import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { theme } from '../utils/theme';

const Link = ({ children, to, duration = 400, offset, ...rest }) => {
  const isScrollLink = /^#/.test(to);

  if (isScrollLink) {
    return (
      <ScrollLink
        href={to}
        to={to.replace('#', '')}
        smooth={true}
        duration={duration}
        offset={offset || -theme.headerHeight}
        {...rest}
      >
        {children}
      </ScrollLink>
    );
  }
  return (
    <a href={to} target="_blank" rel="noreferrer noopener" {...rest}>
      {children}
    </a>
  );
};

export default Link;
