import React from 'react';
import { css } from 'linaria';

import { theme } from '../utils/theme';
import Section from './layout/Section';
import Link from './Link'

import paper from '../images/icons/paper-white.svg';
import callstack from '../images/icons/callstack.svg';

const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h5 {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 24px;
      text-transform: uppercase;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
    }
    a {
      color: ${theme.colors.white};
      font-size: 14px;
      line-height: 24px;
      text-decoration: none;
    }
  `,
  description: css`
    width: 100%;
    @media (min-width: 768px) {
      width: 70%;
    }
    img {
      height: 50px;
    }
    p {
      margin-top: 16px;
      font-size: 14px;
      line-height: 24px;
      max-width: 330px;
      font-weight: 300;
      letter-spacing: 2;
    }
    a {
      text-decoration: underline;
    }
  `,
  info: css`
    width: 50%;
    @media (min-width: 768px) {
      width: 15%;
    }
    & li {
      font-weight: 300;
    }

    & h5 {
      margin-top: 0;
    }
  `,
  copyright: css`
    display: flex;
    font-size: 12px;
    flex-flow: column;
    line-height: 24px;
    margin-top: 40px;
    align-items: center;

    @media (min-width: 768px) {
      flex-flow: row;
      justify-content: space-between;
      margin-top: 100px;
    }

    div {
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
      }
    }
  `,
  copyrightCallstack: css`
    a {
      height: 18px;
    }
  `,
  sectionStyles: css`
    padding-top: 60px;
    padding-bottom: 16px;
  `
};

function Footer() {
  return (
    <footer>
      <Section dark outerStyles={styles.sectionStyles}>
        <div className={styles.container}>
          <div className={styles.description}>
            <img src={paper} alt="" />
            <p>
              react-native-paper is a high-quality standard-compliant Material
              Design library developed by the engineers of{' '}
              <Link to="https://callstack.com/">Callstack.</Link>
            </p>
          </div>
          <div className={styles.info}>
            <h5>info</h5>
            <ul>
              <li>
                <Link to="https://github.com/callstack/react-native-paper">
                  Github
                </Link>
              </li>
              <li>
                <Link to="https://callstack.github.io/react-native-paper/index.html">
                  Docs
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/rn_paper">Twitter</Link>
              </li>
              <li>
                <Link to="https://discord.gg/zwR2Cdh">Discord</Link>
              </li>
            </ul>
          </div>
          <div className={styles.info}>
            <h5>callstack</h5>
            <ul>
              <li>
                <Link to="https://callstack.com/careers/">Careers</Link>
              </li>
              <li>
                <Link to="https://callstack.com/our-work/">Our work</Link>
              </li>
              <li>
                <Link to="https://callstack.com/contact-us/">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.copyright}>
          <div>&#169; 2021 Callstack, All rights reserved</div>
          <div className={styles.copyrightCallstack}>
            Created with love by:
            <Link to="https://callstack.com/">
              <img src={callstack} alt="" />
            </Link>
          </div>
        </div>
      </Section>
    </footer>
  );
}

export default Footer;
