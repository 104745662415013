import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { Sticky } from 'react-sticky';

import { theme } from '../../utils/theme';
import Link from '../Link';
import Section from '../layout/Section';

import paperIcon from '../../images/icons/paper-vector.svg';
import paperWhiteIcon from '../../images/icons/paper-vector-white.svg';
import githubIcon from '../../images/icons/github.svg';
import githubWhiteIcon from '../../images/icons/github-white.svg';

const sectionOuterStyles = css`
  padding: 0;
`;

const sectionInnerStyles = css`
  max-width: unset;
`;

const githubIconLinkStyles = css`
  align-self: center
`

const HeaderOuter = styled.header`
  background: ${({ isSticky }) =>
    isSticky ? theme.colors.primary : 'transparent'};
  box-shadow: ${({ isSticky }) =>
    isSticky
      ? '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
      : 'none'};
  color: ${({ isSticky }) =>
    isSticky ? theme.colors.white : theme.colors.black};
  padding: 15px 15px;
  position: relative;
  transition: background 0.4s, color 0.4s, box-shadow 0.4s;
  z-index: 50;
  display: flex;

  @media (min-width: 900px) {
    height: ${theme.headerHeight};
  }

  .contact-link--mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (min-width: 900px) {
      display: none;
    }
  }
`;

const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: row;
  justify-content: center;
  margin: 0 auto;
  max-width: 940px;
  padding: 0 15px;

  @media (min-width: 900px) {
    justify-content: space-between;
  }
`;

const HeaderLogo = styled.img`
  display: none;
  width: 140px;

  @media (min-width: 900px) {
    display: block;
  }
`;

const HeaderText = styled.p`
  font-size: 14px;
  margin: 0;

  @media (min-width: 600px) {
    font-size: initial;
  }

  a {
    cursor: pointer;
    font-family: 'Platform Web';
    text-decoration: underline;
    color: inherit;
  }
`;

function Header() {
  return (
    <Section outerStyles={sectionOuterStyles} innerStyles={sectionInnerStyles}>
      <Sticky>
        {({ distanceFromTop, style }) => (
          <HeaderOuter style={style} isSticky={distanceFromTop < -15}>
            <Link
              to="#contact"
              duration={750}
              className="contact-link--mobile"
            />
            <HeaderInner>
              <Link
                to="#hero"
                duration={750}
              >
                <HeaderLogo
                  src={distanceFromTop < -15 ? paperWhiteIcon : paperIcon}
                  alt=""
                />
              </Link>
              <HeaderText>
                Need a customized UI kit for Android & iOS?{' '}
                <Link
                  to="#contact"
                  duration={750}
                >
                  Get started
                </Link>
              </HeaderText>
            </HeaderInner>
            <Link 
              to="https://github.com/callstack/react-native-paper"
              className={githubIconLinkStyles}
            >
              <img src={distanceFromTop < -15 ? githubWhiteIcon : githubIcon} alt="Github logo" />
            </Link>
          </HeaderOuter>
        )}
      </Sticky>
    </Section>
  );
}

export default Header;
