import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';

import { theme } from '../../utils/theme';

import darkPattern from '../../images/patterns/dark-pattern.png';
import lightPattern from '../../images/patterns/light-pattern.png';

const SectionOuter = styled.section`
  padding: 60px 30px;
  background: ${({ dark }) =>
    dark ? theme.colors.primary : theme.colors.white};
  color: ${({ dark }) => (dark ? theme.colors.white : theme.colors.black)};
  background-position: -5px 10px;
  @media (min-width: 992px) {
    padding: 60px 15px;
  }
  `;

const lightSectionStyles = css`
background-image: url(${lightPattern});

`

const darkSectionStyles = css`
background-image: url(${darkPattern});
`

const SectionInner = styled.div`
  max-width: 940px;
  margin: 0 auto;
`;

function Section({ dark, children, outerStyles, innerStyles }) {
  return (
    <SectionOuter dark={dark} className={`${dark ? darkSectionStyles : lightSectionStyles} ${outerStyles}`}>
      <SectionInner className={innerStyles}>{children}</SectionInner>
    </SectionOuter>
  );
}

export default Section;
