import React from 'react';
import { Helmet } from 'react-helmet';
import { StickyContainer } from 'react-sticky';
import 'typeface-roboto';

import Header from '../homepage/Header';
import Footer from '../Footer';

import '../../styles/reset.css';
import '../../styles/fonts.css';
import '../../styles/typography.css';

import appleTouchIcon from '../../images/favicon/apple-touch-icon.png';
import favicon32 from '../../images/favicon/favicon-32x32.png';
import favicon16 from '../../images/favicon/favicon-16x16.png';
import safariPinned from '../../images/favicon/safari-pinned-tab.svg';
import favicon from '../../images/favicon/favicon.ico';

function Layout({ children }) {
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@callstackio" />
        <meta name="twitter:creator" content="@callstackio" />
        <meta name="twitter:title" content="React Native Paper" />
        <meta name="twitter:image" content="https://reactnativepaper.com/img/og-image.png" />
        <meta property="og:title" content="React Native Paper" />
        <meta
          property="og:description"
          content="React Native Paper is a high-quality, standard-compliant Material Design library that has you covered in all major use-cases."
        />
        <meta
          property="og:image"
          content="https://reactnativepaper.com/img/og-image.png"
        />

        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="mask-icon" href={safariPinned} color="rgba(33, 0, 93, 1)" />
        <link rel="shortcut icon" href={favicon} />
        <title>React Native Paper</title>
      </Helmet>
      <StickyContainer>
        <Header />
        {children}
        <Footer />
      </StickyContainer>
    </>
  );
}

export default Layout;
